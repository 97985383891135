import React from 'react';
import { useLocation } from 'react-router-dom';
import TestNavbar from './components/teastpage/TestNavbar';

const Layout = ({ children }) => {
  const location = useLocation();
  const excludeNavbarRoutes = ['/login-page', '/',"/start-test","/online-test-page","/submit-test"];

  if (excludeNavbarRoutes.includes(location.pathname)) {
    return <>{children}</>;
  }

  return (
    <TestNavbar>
      {children}
    </TestNavbar>
  );
};

export default Layout;
