

import { Box, Button,CircularProgress } from '@mui/material'
import { Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import { useConfig } from '../../ConfigContext'




const StartTest = () => {
    const config= useConfig()
    console.log("config",config)
    const [data, setData] = useState()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(true)
   
    useEffect(() => {
        const fatchData = async () => {
            try {
                const response = await axios.get(`http://p9/LitteraAPI/api/agency?APIKEY=12345678@&agencytype=00001&agencyid=DFF7C661-5B84-4A7E-8250-31C420DD9FCD`)
                setData(response.data.pagedata[0])
                console.log("response-test", response.data.pagedata[0])
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        fatchData()
    }, [])
    if (loading) {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                backgroundColor: "#f9f9f9",
            }}>
                <CircularProgress />
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Loading...
                </Typography>
            </Box>
        );
    }
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            backgroundColor: "#f9f9f9",

        }}>
            <Box sx={{ textAlign: "center", mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    // src={require("../../../src/assest/logo_pra1.png")}
                    src={`${config?.LITTERA_CDN_BASE_URL}/${data?.ag_photo_path}`}
                    alt='logo'
                    style={{ width: '66px' }}
                />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '2rem', // Adjust the font size as needed
                    }}
                >
                    {data?.HAgencyName}
                </Typography>
                <Typography variant="body2" style={{ fontSize: 'medium' }}>
                    {data?.Ag_Address}
                </Typography>
            </Box>
            <Box sx={{
                width: "88%",
                backgroundColor: "#e0f7fa",
                p: 4,
                borderRadius: 2,
                boxShadow: 2,
                textAlign: 'center',
            }}>
                <Typography variant="h6" color="#31708f" style={{ fontSize: "medium" }}> Click to start to begin your exam !</Typography>
                <NavLink to="/online-test-page">
                    <Button variant="contained" color="primary" size="large">
                        Start
                    </Button>
                </NavLink>
            </Box>

        </Box>
    )
}

export default StartTest

