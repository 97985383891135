import React, { useState, useEffect ,useContext} from "react";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
import loadConfig from "../../config";
import axios from "axios";
import hex_md5 from "md5";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserTypeModal from "../modals/UserTypeModal";
import { useUser } from "../../UserContext"; // Adjust the path as needed

import { useConfig } from '../../ConfigContext';
import ForgetPassword from "./ForgetPssword";
import { TranslationContext } from "../../TranslationContext";



const generateSalt = (length) => {
  const characters =
    "QWERTYUIOPLKJHGFDSAZCXCVBNMzaqwsxcderfvtgbyhnujmikolp0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const LoginPage = ({ profileDetails, login }) => {
  console.log("LoginPage", profileDetails)
  const navigate = useNavigate();
  const { setUser, setClient, setSelectedUserType } = useUser(); // Access setUser from UserContext
  const { translations } = useContext(TranslationContext);
  const config1 = useConfig();
  // console.log(config1)
  const [loginType, setLoginType] = useState("emailid"); // Default to mobileno, change as needed
  const [emailid, setEmailid] = useState("");
  const [emailError, setEmailError] = useState('');
  const [mobileno, setMobilno] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [mode, setMode] = useState("password");
  const [error, setError] = useState(null);
  const [config, setConfig] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [timer, setTimer] = useState(30); // Timer state for the countdown
  const [countres, setCountres] = useState([]); // State to hold countries data
  const [clientData, setClientData] = useState('')
  const [loading, setLoading] = useState(false); // Loading state for form submissions
  const [otpLoading, setOtpLoading] = useState(false); // Loading state for OTP request
  const [showOTPLogin, setShowOTPLogin] = useState(false); // State to determine if OTP login should be shown


  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    setIsOpen(false); // Open the modal when component mounts
  }, []); // Empty dependency array ensures this effect runs only once on mount
  useEffect(() => {
    // Fetch countries data and set default selectedCountry to '91' (India)
    if (countres.length > 0) {
      const defaultCountry = countres.find(country => country.countryCode === '91');
      setSelectedCountry(defaultCountry);
    }
  }, [countres]);

  const fetchConfig = async () => {
    try {
      console.log("Loading config data...");
      const configData = await loadConfig(); // Assuming loadConfig fetches your config object
      console.log("Config data loaded:", configData);
      setConfig(configData);
      fetchClientData(configData);
      console.log("ccc", configData);
      //fetchAdditionalData();
      return configData;


    } catch (error) {
      console.error("Error loading config:", error);
    }
  };

  //==============Show to OTP Login Start ======================


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!config) return; // Ensure config is loaded before making API call

        const headers = {
          APIKey: config.REACT_APP_API_KEY,
        };

        const response = await axios.get(
          `${config.REACT_APP_API_URL}/Get_Application_Setting`,
          {
            params: {
              settingtype: 6,
            },
            headers: headers,
          }
        );

        if (
          response.data &&
          response.data.otP_LOGIN_REQUIRED !== undefined &&
          response.data.otP_LOGIN_REQUIRED === 1
        ) {
          setShowOTPLogin(true);
        } else {
          setShowOTPLogin(false);
        }
        console.log("OTP required:", response);
      } catch (error) {
        console.error("Error fetching application setting:", error);
        // Handle error if needed
      }
    };

    fetchData();
  }, [config]); // Include config as a dependency


  // Countdown timer effect
  useEffect(() => {
    if (mode === 'otp') {
      let count = 30; // Countdown duration in seconds
      setTimer(count);
      const countdown = setInterval(() => {
        count--;
        setTimer(count);
        if (count === 0) {
          clearInterval(countdown);
        }
      }, 1000);
      // Clear timer and re-enable switchToOTPMode after 30 seconds

    }
  }, [mode]);

  const handleChange = (e) => {
    const value = e.target.value;

    // Check if loginType is emailid
    if (loginType === "emailid") {
      setEmailid(value);
      setMobilno(""); // Clear mobileno field
    }
    // Check if loginType is mobileno
    else if (loginType === "mobileno") {
      // Check if the value contains only numbers
      if (/^\d*$/.test(value)) {
        setMobilno(value); // Update mobileno state only if it's numeric
      } else {
        setMobilno(''); // Clear mobileno input if it contains non-numeric characters
      }
      setEmailid(""); // Clear emailid field
    }
  };


  const handleBlur = () => {
    // Regular expression to validate email format
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (loginType === "emailid" && !regex.test(emailid)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };


  const handleYesClick = () => {
    setIsOpen(false);
    navigate("/dashboard");
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    navigate("/dashboard");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); // Set loading state to true

    if (mode === "password") {
      // Password login logic
      try {
        const salt = generateSalt(8);
        let hashedPassword = hex_md5(password).toString();
        hashedPassword = hex_md5(salt.toString() + hashedPassword.toString());

        let loginData = {};
        if (loginType === "emailid") {
          loginData = { emailid, password: hashedPassword, salt };
        } else if (loginType === "mobileno") {
          loginData = { mobileno: `${selectedCountry.countryCode}-${mobileno}`, password: hashedPassword, salt };
        }

        const headers = {
          "Content-Type": "application/json",
          APIKey: config.REACT_APP_API_KEY,
        };
        const response = await axios.post(
          `${config.REACT_APP_API_URL}/GetToken`,
          loginData,
          { headers }
        );

        // Store user data in context
        setUser(response.data.result);
        localStorage.setItem('user', JSON.stringify(response.data.result));
        // Check if modal should be opened (in your case, based on usertype condition)
        const shouldOpenModal = response.data.result.userdetails.usertype.length > 1;

        if (!shouldOpenModal) {
          // Navigate to dashboard if modal should not open
          setSelectedUserType(response.data.result.userdetails.usertype[0].usertypeid)
          navigate("/dashboard");
          toast.success("Login successful!");
        } else {
          // Open modal if necessary
          setIsOpen(true);
          toast.success("Login successful!");
        }
      } catch (error) {
        console.error("Error in logging in:", error);
        toast.error(error.response.data || "Error in Login")
        setError("Failed to login. Please check your credentials.");
      } finally {
        setLoading(false); // Set loading state back to false
      }
    } else if (mode === "otp") {
      // OTP login logic
      try {
        const loginData = {
          emailid: loginType === "emailid" ? emailid : `${selectedCountry?.countryCode}-${mobileno}`,
          mobile: `${selectedCountry?.countryCode}-${mobileno}`,
          otp,
          countryCode: selectedCountry?.countryCode,
        };

        const headers = {
          "Content-Type": "application/json",
          APIKey: config.REACT_APP_API_KEY,
        };
        const response = await axios.post(
          `${config.REACT_APP_API_URL}/GetToken`,
          loginData,
          { headers }
        );

        // Store user data in context
        setUser(response.data.result);
        localStorage.setItem('user', JSON.stringify(response.data.result));
        // Check if modal should be opened (in your case, based on usertype condition)
        const shouldOpenModal = response.data.result.userdetails.usertype.length > 1;

        if (!shouldOpenModal) {
          // Navigate to dashboard if modal should not open
          navigate("/dashboard");
          toast.success("Login successful!");
        } else {
          // Open modal if necessary
          setIsOpen(true);
          toast.success("Login successful!");
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        toast.error("Error verifying OTP");
        setError("Failed to verify OTP. Please check your OTP.");
      } finally {
        setLoading(false); // Set loading state back to false
      }
    }
  };

  const handleGetOTP = async () => {
    setOtpLoading(true); // Set OTP loading state to true

    try {
      const headers = {
        "Content-Type": "application/json",
        APIKey: config.REACT_APP_API_KEY,
      };

      const response = await axios.get(
        `${config.REACT_APP_API_URL}/GenerateOTP`,
        {
          params: {
            username: loginType === "emailid" ? emailid : `${selectedCountry.countryCode}-${mobileno}`,
          },
          headers,
        }
      );
      console.log(response);
      toast.success("OTP sent successfully!");

      // Start the timer only after OTP is successfully sent
      startOTPTimer();

      // Only change mode to "otp" if OTP is successfully sent
      setMode("otp");
    } catch (error) {
      console.error("Error fetching OTP:", error);
      toast.error("Failed to fetch OTP. Please try again.");
      // Optionally, reset timer if OTP sending fails
      setTimer(0);
      setMode("password");
      // Do not change mode to "otp" if OTP sending fails
      // If you want to keep mode as "password" in case of error, remove this line:
      // setMode("otp");
    } finally {
      setOtpLoading(false); // Set OTP loading state back to false
    }
  };
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  // Function to validate mobile number format (example)
  const validateMobileNumber = (mobileNumber) => {
    // Example pattern for validation, adjust as per your requirements
    const regex = /^[0-9]{10}$/;
    return regex.test(mobileNumber);
  };

  const switchToOTPMode = () => {
    // Validation checks
    if (loginType === "emailid" && (!emailid || !validateEmail(emailid))) {
      toast.error("Please enter a valid Email Address.");
      return;
    }

    if (loginType === "mobileno" && (!mobileno || !validateMobileNumber(mobileno))) {
      toast.error("Please enter a valid Mobile Number.");
      return;
    }

    // Proceed to OTP mode and send OTP
    //setMode("otp");
    handleGetOTP();
  };

  // Function to start OTP timer
  const startOTPTimer = () => {
    setTimeout(() => {
      setTimer(0); // Reset timer after 30 seconds
    }, 30000);
  };




  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setIsOpen(false); // Close dropdown after selection
  };

  useEffect(() => {
    const initializeConfig = async () => {
      const configData = await fetchConfig();
      if (configData) {
        setLoading(false);
        fetchData(configData); // Fetch initial data once config is loaded
        fetchClientData(configData); // Fetch initial data once config is loaded
        
      }
    };
    initializeConfig();
  }, []);
  const fetchData = async (config) => {

    try {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config.REACT_APP_API_KEY, // Use config.REACT_APP_API_KEY instead of process.env.REACT_APP_REACT_APP_API_KEY
        "Content-Type": "application/json",
      };

      const response = await axios.get(`${config.REACT_APP_API_URL}/country`, {
        headers,
      });
      setCountres(response.data)
      if (response.data.length > 0) {
        setSelectedCountry(response.data[0]); // Select the first country by default
      }
      console.log(response)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchClientData = async (config) => {
    console.log(config)
    try {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: config.REACT_APP_API_KEY, // Use config.REACT_APP_API_KEY instead of process.env.REACT_APP_REACT_APP_API_KEY
        "Content-Type": "application/json",
      };

      const response = await axios.get(`${config.REACT_APP_API_URL}/GetClientData`, {
        headers,
      });
      setClientData(response.data)
      setClient(response.data)
      localStorage.setItem('clientData', JSON.stringify(response.data));
      console.log(clientData)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLoginTypeChange = (value) => {
    setLoginType(value);
    setEmailid(''); // Clear email input
    setMobilno(''); // Clear mobile number input
  };
  //=========Login with Google ===============//

  useEffect(() => {
    const checkEmailAndNavigate = async () => {
      if (profileDetails.verified_email) {
        try {
          // Construct the API URL with the dynamic email from profileDetails
          const apiUrl = `${config.REACT_APP_API_URL}/userinfo?username=${encodeURIComponent(profileDetails.email)}`;

          try {
            const response = await fetch(apiUrl, {
              headers: {
                Accept: 'application/json, text/plain',
                APIKey: config.REACT_APP_API_KEY,
              },
            });
          
            if (!response.ok) {
              // Handle non-2xx HTTP responses
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
          
            const data = await response.json();
             setUser(data.result);
                    localStorage.setItem('user', JSON.stringify(data.result));
                    // Check if modal should be opened (in your case, based on usertype condition)
                    const shouldOpenModal = data.result.userdetails.usertype.length > 1;
          
                    if (!shouldOpenModal) {
                      // Navigate to dashboard if modal should not open
                      navigate("/dashboard");
                      toast.success("Login successful!");
                    } else {
                      // Open modal if necessary
                      setIsOpen(true);
                      toast.success("Login successful!");
                    }
          
          } catch (error) {
            // Handle errors here (network errors, JSON parsing errors, etc.)
            console.error('Error fetching user info:', error);
          }
          
        } catch (error) {
          console.error("Error in Login:", error);
          toast.error("Error in Login");
          setError("Failed to  Login. Please check your Email.");
        } finally {
          setLoading(false); // Set loading state back to false
        }
      }
    };

    checkEmailAndNavigate();
  }, [profileDetails, navigate]);






  return (
    <>
      <div className="min-h-screen  flex flex-col justify-center pb-12 sm:px-2 lg:px-4" style={{ backgroundColor: "#428bca" }}>
        <ToastContainer />
        <UserTypeModal
          isOpen={isOpen}
          onClose={handleCloseModal}
          onYesClick={handleYesClick}
        />

        <div className="sm:mx-auto" style={{margin:"2rem"}}>
          <div
            className="bg-white shadow sm:rounded-lg"
            style={{ paddingTop: "3rem", paddingLeft: "5rem", paddingRight: "5rem", paddingBottom: "3rem" }}
          >
            <div
              className="image-section"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <img
                src={`${config1?.LITTERA_CDN_BASE_URL}/${clientData.logiN_LOGO}`}
                className="login-logo"
                style={{ width: "71px", height: "71px" }}
              />

            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md" style={{ marginBottom: "20px" }}>
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                
                {translations["your_account"] || "Sign in to your account"}
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Or
                <a
                  href="#"
                  className="font-medium  hover:text-blue-500"
                  style={{ marginLeft: "5px", color: "#428bca" }}
                >
                  {translations["create_account"] || "create an account"}
                  
                </a>
              </p>
            </div>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="flex items-center space-x-4">
                <div className="flex" style={{ alignItems: 'baseline' }}>
                  <input
                    id="login_with_email"
                    name="login_type"
                    type="radio"
                    value="emailid"
                    checked={loginType === 'emailid'}
                    onChange={() => handleLoginTypeChange('emailid')}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="login_with_email"
                    className="ml-2 block text-sm font-medium text-gray-700"
                  >
                     {translations["Login_with_Email" ]||"Login with Email"}
                    
                  </label>
                </div>
                <div className="flex" style={{ alignItems: 'baseline' }}>
                  <input
                    id="login_with_mobileno"
                    name="login_type"
                    type="radio"
                    value="mobileno"
                    checked={loginType === 'mobileno'}
                    onChange={() => handleLoginTypeChange('mobileno')}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="login_with_mobileno"
                    className="ml-2 block text-sm font-medium text-gray-700"
                  >
                    {translations["Login_with_Mobile_Number"] || "Login with Mobile Number"}
                  
                  </label>
                </div>
              </div>


              {loginType === "emailid" ? (
                <div>
                  <label
                    htmlFor="emailid"
                    className="block text-sm font-medium text-gray-700"
                  >
                    
                    {translations["Email_address"] || "Email address"}
                  </label>
                  <div className="mt-1">
                    <input
                      id="emailid"
                      name="emailid"
                      type="email"
                      autoComplete="email"
                      required
                      value={emailid}
                      onChange={handleChange}
                      onBlur={handleBlur} // Check validity on blur
                      className={`appearance-none rounded-md relative block w-full  border ${emailError ? 'border-red-500' : 'border-gray-300'} placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10`}
                      style={{ fontSize: "15px", padding: "10px" }}
                      placeholder= {translations["Enter_your_emailaddress"] || "Enter your Email address"}
                     
                    />
                    {emailError && (
                      <p className="text-red-500 text-sm mt-1">{emailError}</p>
                    )}
                  </div>
                </div>
              ) : (
           
                <div className="flex items-center"> {/* Flex container to align items */}
                  <div className="custom-dropdown relative rounded-md shadow-sm">
                    <div className="selected-item flex items-center" onClick={toggleDropdown} style={{ border: "1px solid #efdbdb", backgroundColor: "#e4e7ea", marginTop: "3px", padding: "1px", borderTopLeftRadius: "3px", borderButtomLeftRadius: "3px" }}>
                      <img
                        src={`${config.LITTERA_CDN_BASE_URL}/${config.LITTERA_CDN_ASSET_FLAG_PATH}/${selectedCountry?.flagPath}`} // Constructing the full URL
                        alt="Flag"
                        className="dropdown-image"
                        style={{ marginRight: '8px', padding: "7px", height: "34px" }}
                      /> {/* Flag image */}

                      <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>
                    </div>
                    {isOpen && (
                      <div className="dropdown-list absolute top-0 left-0 bg-white border border-gray-300 mt-1 rounded-md shadow-lg z-10">
                        {countres.map((country) => (
                          <div
                            key={country.code}
                            className="dropdown-item py-2 px-4 cursor-pointer flex items-center"
                            onClick={() => handleCountryChange(country)}
                          >
                            <img src={`${config.LITTERA_CDN_BASE_URL}/${config.LITTERA_CDN_ASSET_FLAG_PATH}/${country?.flagPath}`} alt="Flag" className="dropdown-image" style={{ marginRight: '8px', padding: "7px", height: "34px" }} />

                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="mt-1 relative rounded-md shadow-sm flex-grow">
                    <input
                      type="tel"
                      name="mobileno"
                      id="mobileno"
                      autoComplete="tel"
                      required
                      pattern="[0-9]*"
                      maxLength="10"
                      value={mobileno}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        // Allow only numbers
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-4  border border-gray-300 rounded-md"
                      style={{ padding: "10px", fontSize: "15px" }}
                      placeholder={translations["Enter_your_phone_number"] || "Enter your phone number"}
                      
                      title="Please enter your 10-digit mobile number"
                    />


                  </div>


                </div>

              )}

              {mode === "password" ? (
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                 {translations["Password"] || "Password"}
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="appearance-none rounded-md relative block w-full  border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10"
                      style={{ fontSize: "15px", padding: "10px" }}
                      placeholder={translations["Enter_password"] || "Enter your password"}
                                       
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <label
                    htmlFor="otp"
                    className="block text-sm font-medium text-gray-700"
                  >
                {translations["OTP"] || "OTP"}
                  </label>
                  <div className="mt-1">
                    <input
                      id="otp"
                      name="otp"
                      type="text"
                      autoComplete="off"
                      required
                      value={otp}
                      onChange={(e) => setOTP(e.target.value)}
                      className="appearance-none rounded-md relative block w-full border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      style={{ fontSize: "15px", padding: "10px" }}
                      placeholder="Enter OTP"
                    />
                  </div>
                </div>
              )}
              {showOTPLogin ? (
                mode === "password" ? (
                  <div className="mt-2">
                    <a
                      href="#"
                      style={{ color: '#428bca' }}
                      onClick={switchToOTPMode}
                      className="font-medium hover:text-[#428bca]"
                    >
                        {translations["Click_To_Get_OTP"] ||"Click To Get OTP"}
                      
                    </a>
                  </div>
                ) : (
                  <div className="mt-2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <a
                      href="#"
                      onClick={timer > 0 ? null : switchToOTPMode}
                      className={`font-medium text-blue-600 hover:text-blue-500 ${timer > 0 ? 'cursor-not-allowed' : ''}`}
                    >
                      {timer > 0 ? `Re-Send OTP (${timer}s)` : "Re-Send OTP"}
                    </a>
                    <a
                      href="#"
                      onClick={() => setMode('password')}
                      className="font-medium text-blue-600 hover:text-blue-500"
                    >
                       {translations["Enter Password"] || "Enter Password"}
                      
                    </a>
                  </div>
                )
              ) : null}




              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-4 px-4 border border-transparent text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  style={{ lineHeight: "22px", backgroundColor: "#428bca", }} disabled={loading}
                >
                  {loading ? 'Logging in...' : 'Log In'}
                </button>
              </div>

            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gray-100 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>
              <div className="social">
                <div className="go" onClick={login} style={{ backgroundColor: "#428bca" }}>
                  <GoogleIcon
                    className="fab fa-google"
                    style={{
                      fontSize: "21px",
                      color: "white",
                      marginRight: "10px",
                    }}
                  />
                  Google

                </div>

              </div>
              <ForgetPassword />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
