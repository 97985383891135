// App.js

import React, { useState, useEffect } from "react";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import logo from "./logo.svg";
import "./App.css";
import "./css/morris.css";
import "./css/select2.css";
import "./css/style.default.css";
import "./css/newstyle.css";
// import "./css/bootstrap.min.css";
//import "./css/bootstrap-override.css";
import "./css/weather-icons.min.css";
import "./css/jquery-ui-1.10.3.css";
import "./css/font-awesome.min.css";
import "./css/animate.min.css";
import "./css/animate.delay.css";
import "./css/toggles.css";
import "./css/pace.css";
import "../src/componentHome/header/header.css";
import "../src/componentHome/home/about.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FuncationAntPage from "./pages/FuncationAntPage"
import DashboardPage from "./pages/DasboardPage";
import HomePage from "./pages/HomePage";
import AddPositionSidebarPage from "./pages/AddPositionSideberPage";
import LoginPage from "./componentHome/login&ragister/LoginPage";
import DasboardPage from "./pages/DasboardPage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "./ThemeProvider";
import Litteraroom from "./pages/Litteraroom";
import JobPositionAntPage from "./pages/JobPositionAntPage";
import JobRollAntPage from "./pages/JobRollAntPage";
import AddActivitesPage from "./pages/AddActivitesPage";
import LoginPost from "./componentHome/login&ragister/LoginPost";
import ImageUpload from "./components/navbar/Test";

import FeedbackPage from "./pages/FeedbackPage";
import TestListPage from "./pages/TestListPage";
import AutoLogout from "./AutoLogout";
import SessionCalender from "./components/Content/Calender/SessionCalender";
import TestSessionCalendar from "./pages/TestSessionCalendar";
import MeetingListPage from "./pages/MeetingListPage";
import RedirectPage from "./componentHome/meeting/UrlCopyLink";
import SelfTestPage from "./pages/SelfTestPage";
import OnlineTest from "./componentHome/self-test/ChatUi";
import StartTest from "./componentHome/self-test/StartTest";
import TestSubmit from "./componentHome/self-test/TestSubmit";

import TestNavbar from "./components/teastpage/TestNavbar";
import Layout from "./Layout";


const App = () => {
  
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(
    () => {
      if (user) {
        user == [] ? console.log(user) : console.log("Empty user")
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            setProfile(res.data);
            console.log("data assigned");
          })
          .catch((err) => console.log(err));
      }
    },
    [user]
  );

  const logOut = () => {
    googleLogout();
    setProfile([]);
  };


  return (

    <Router>
      <AutoLogout>
        <ThemeProvider>
          {/* <Breadcrumb /> */}
          <ToastContainer />
          <Layout>

            <Routes>


              <Route path="/" element={<HomePage />} />
              <Route path="/home" element={<DashboardPage />} />
              <Route path="/google-login" element={<LoginPost />} />
              <Route
                path="/funcation-panel/add-position-sidebar"
                element={<AddPositionSidebarPage />}
              />
              <Route path="/login-page" element={<LoginPage profileDetails={profile} login={login} logout={logOut} />} />
              <Route path="/function-panel" element={<FuncationAntPage />} />
              <Route path="/job-position" element={<JobPositionAntPage />} />
              <Route path="/job-role" element={<JobRollAntPage />} />
              <Route path="/dashboard" element={<DasboardPage />} />
              <Route path="/job-activity" element={<AddActivitesPage />} />
              <Route path="/test-list" element={<TestListPage />} />
              <Route path="/meeting-list" element={<MeetingListPage />} />
              <Route path="/Litteraroom" element={<Litteraroom />} />
              <Route path="/Feedback/Feedback" element={<FeedbackPage />} />
              <Route path="/upload" element={<ImageUpload />} />
              <Route path="/redarict/:shortUrl" element={<RedirectPage />} />
              <Route path="/redarict" element={<RedirectPage />} />
              {/* <Route path="/LMS_Transaction/frm_Session_Calendar.aspx" element={<TestSessionCalendar />} /> */}
              <Route path="/Session_Calendar" element={<TestSessionCalendar />} />
              <Route path="/Litteraroom/session/:id" element={<Litteraroom />} />
              <Route path="/selftest-page" element={<SelfTestPage />} />
              <Route path="/online-test-page" element={<OnlineTest />} />
              <Route path="/start-test" element={<StartTest />} />
              <Route path="/submit-test" element={<TestSubmit />} />
            </Routes>

          </Layout>
        </ThemeProvider>
      </AutoLogout>
    </Router>

  );
};

export default App;
