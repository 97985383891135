import React, { createContext, useState, useContext } from 'react';

const FeedbackContext = createContext();

export const useFeedback = () => useContext(FeedbackContext);

export const FeedbackProvider = ({ children }) => {
  const [currentForm, setCurrentForm] = useState('content');
  const [roomSessions, setroomSessions] = useState(null);
  const [facultyFeedback, setFacultyFeedback] = useState(null);
  const [contentFeedback, setContentFeedback] = useState(null);
  const [isVisible, setisVisible] = useState(false)
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [sessiondata, setsessiondata] = useState(null);
  const [SingleSessionData, setSingleSessionData] = useState(null);
  const [selectedSessionContent, setSelectedSessionContent ,] = useState(null);
  const [sessionContent, setsessionContent] = useState(null);
  const [PageSize, setPageSize] = useState(10);
  const [PageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [ContentType, setContentType] = useState(null);
  const [Notes, setNotes] = useState(null)
  const [Comments, setComments] = useState(null)
  const [Video, setVideo] = useState([]);
  const [Audio, setAudio] = useState([]);
  const [pdf, setpdf] = useState([]);
  const [WYISIWYG, setWYISIWYG] = useState([]);
  const [Image, setImage] = useState([]);
  const [Cdn, setCdn] = useState([]);
  const [interactive, setinteractive] = useState([]);
  const [documents, setdocuments] = useState([]);
  const [otherdocuments, setotherdocuments] = useState([]);  
  const [Link, setLink] = useState([]);  
  const [htmlid, sethtmlid] = useState("");  
  const [Questions, setQuestions] = useState(null);  
  const [ContentQuestions, setContentQuestions] = useState([]);  
  const [FeedbackQuestions, setFeedbackQuestions] = useState([]);  
  const [feedback, setFeedback] = useState([]); 
  const [isReverse, setisReverse] = useState(false); 
  const [completion, setcompletion] = useState(null); 
  const [isFeedbackSubmitted, setisFeedbackSubmitted] = useState(false); 
  const [meeting, setmeeting] = useState(); 
  const [tabs, setTabs] = useState([]);
  const [whiteboard, setwhiteboard] = useState(false);    
  const [filtercontent, setfiltercontent] = useState("All Content");    
  const [dashboard_analytics, setdashboard_analytics] = useState(null);  
  const [SessionCalendar, setSessionCalendar] = useState(null);  
  const [selectedDate, setSelectedDate] = useState(null);  
  const [showModal, setShowModal] = useState(false);
  const [sessionType, setsessionType] = useState(null);
  const [allfaculty, setallfaculty] = useState(null);
  const [sessionModules, setsessionModules] = useState(null);
  


  return (
    <FeedbackContext.Provider 
      value={{ 
          currentForm, setCurrentForm , 
          facultyFeedback, setFacultyFeedback , 
          contentFeedback, setContentFeedback , 
          isVisible, setisVisible , 
          roomSessions, setroomSessions,        
          selectedSessionId, setSelectedSessionId ,
          sessiondata, setsessiondata,
          selectedSessionContent, setSelectedSessionContent ,
          sessionContent, setsessionContent ,
          loading, setLoading,
          PageSize, setPageSize,
          PageNumber, setPageNumber,
          ContentType, setContentType,
          Notes, setNotes,
          Comments, setComments,
          Video, setVideo,
          Audio, setAudio,
          pdf, setpdf,
          WYISIWYG, setWYISIWYG,
          Image, setImage,
          Cdn, setCdn,
          interactive, setinteractive,
          otherdocuments, setotherdocuments,
          documents, setdocuments,
          Link, setLink,          
          htmlid, sethtmlid,
          Questions, setQuestions,
          ContentQuestions, setContentQuestions,
          FeedbackQuestions, setFeedbackQuestions,
          SingleSessionData, setSingleSessionData,
          feedback, setFeedback , 
          isReverse, setisReverse,
          completion, setcompletion,
          isFeedbackSubmitted, setisFeedbackSubmitted,
          meeting, setmeeting,
          tabs, setTabs,
          whiteboard, setwhiteboard,
          filtercontent, setfiltercontent,
          dashboard_analytics, setdashboard_analytics,
          SessionCalendar, setSessionCalendar,
          selectedDate, setSelectedDate,
          showModal, setShowModal,
          sessionType, setsessionType,
          allfaculty, setallfaculty,
          sessionModules, setsessionModules
        }}>
      {children}
    </FeedbackContext.Provider>
  );
};
