import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import Breadcrumb from '../commonComponent/Breadcrumbs';
import { Input, Select } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { useConfig } from '../../ConfigContext';
import { useUser } from '../../UserContext';
import { Dropdown, Menu, } from 'antd';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PurePanel from 'antd/es/float-button/PurePanel';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { TranslationContext } from '../../TranslationContext';
const { Option } = Select;

const TestListTable = () => {
    const { translations } = useContext(TranslationContext);

    const navigate = useNavigate()
    const { selectedUserType } = useUser();
    const config = useConfig();
    const [tests, setTests] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [searchTerm, setSearchTerm] = useState('');

    const user = JSON.parse(localStorage.getItem('user'));
    const userid = user.userdetails.agencyid;
    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const getCurrentDate = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };
    // const handlePlayClick = async (test) => {
    //     console.log("Test data:", test);


    //     const payload = {
    //         skillSet:test?.skillTag,
    //         questionCount: test?.noOfQuestion, // Ensure testData is defined in your scope
    //         questionDifficutyID: test?.questionDifficultyID,
    //         trainingCategory: test?.trainingCategoryID,
    //     };

    //     try {
    //         // Make the API call
    //         const response = await axios.post(`${config.REACT_APP_EVAL_API_PATH}/TestQuestions/Questions`, payload, {
    //             headers: {
    //                 "API-Key": "UHJhdGhhbXNvZnRTZWNyZXRLZXkxMjM=",
    //                 'Content-Type': 'application/json' // Ensure the content type is set correctly
    //             }
    //         });

    //         // Check if the response is successful
    //         if (response.status === 200) {
    //             const apiData = response.data; // Get the data from the response
    //             // Navigate to the online test page with test and apiData
    //             navigate('/online-test-page', { state: { test, apiData } });
    //             console.log("apiData",apiData);
    //         } else {
    //             console.error('Error fetching data:', response.statusText);
    //             // Handle any error response as needed
    //         }
    //     } catch (error) {
    //         console.error('Error during API call:', error);
    //         // Handle error (e.g., show a message to the user)
    //     }
    // };
    const handleModalClose = () => {
        setModalOpen(false);
        setModalMessage("");
    };

    const showModal = (message) => {
        setModalMessage(message);
        setModalOpen(true);
    };

    const handlePlayClick = async (test) => {
        console.log("Test data:", test);

        const payload = {
            skillSet: test?.skillTag,
            questionCount: test?.noOfQuestion,
            questionDifficutyID: test?.questionDifficultyID,
            trainingCategory: test?.trainingCategoryID,
        };

        // Define payloads for each API call

        const updateStatusPayload = {
            tptss_id: uuidv4(),
            tptss_testid: test?.testID,
            tptss_participantid: userid,
            tptss_createdon: getCurrentDate(),
            tptss_createdby: userid
        }; // Add any required fields here

        try {
            // Step 1: Check session restriction
            const checkSessionResponse = await axios.get(
                `${config?.REACT_APP_API_URL}/Check_SESSION_RESTRICTION`,
                {
                    params: {
                        usertype: selectedUserType,
                        userid: userid,
                        sessionid: test?.training_SessionID,
                        trainingid: test?.trainingId,
                    },
                    headers: {
                        APIKey: config?.REACT_APP_API_KEY,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (checkSessionResponse.data === true) {
                showModal(translations["current_session_before"] || "You can not start current session before completing the previous sessions.");

                //showModal("You can not start current session before completing the previous sessions.");
                return;
            }

            // Step 2: Check test eligibility
            const eligibilityResponse = await axios.get(
                `${config?.REACT_APP_API_URL}/Check_Test_Eligibility`,
                {
                    params: {

                        userid: userid,
                        trainingid: test?.trainingId,
                        testid: test?.testID,
                    },
                    headers: {
                        APIKey: config?.REACT_APP_API_KEY,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (eligibilityResponse.data !== 1) {
                showModal(translations["Please_contactto_admin"] || "You are not eligible to do this test,Please contact to admin.");
                //showModal("You are not eligible to do this test,Please contact to admin.");
                return;
            }

            // Step 3: Check participant test status
            const statusResponse = await axios.get(
                `${config?.REACT_APP_EVAL_API_PATH}/tests/ParticipantTestStatus`,
                {
                    params: {


                        testid: test?.testID,
                        participantid: userid,
                    },
                    headers: {
                        "API-Key": config?.REACT_APP_EVAL_API_KEY,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (statusResponse.data === false) {
                // Step 4: Update participant test status
                await axios.post(`${config.REACT_APP_EVAL_API_PATH}/Tests/Update_Participant_Test_Status`, updateStatusPayload, {
                    headers: {
                        "API-Key": config?.REACT_APP_EVAL_API_KEY,
                        'Content-Type': 'application/json'
                    }
                });

                // Step 5: Run the test
                const runTestResponse = await axios.post(`${config.REACT_APP_EVAL_API_PATH}/TestQuestions/Questions`, payload, {
                    headers: {
                        "API-Key": config?.REACT_APP_EVAL_API_KEY,
                        'Content-Type': 'application/json'
                    }
                });

                if (runTestResponse.status === 200) {
                    const apiData = runTestResponse.data;
                    // Navigate to the online test page
                    navigate('/online-test-page', { state: { test, apiData } });
                    console.log("apiData", apiData);
                } else {
                    console.error('Error running test:', runTestResponse.statusText);
                }
            } else {
                showModal(translations["already_beensubmitted"] || "This test has already been submitted by you.");
                //showModal("This test has already been submitted by you.");
            }
        } catch (error) {
            console.error('Error during API calls:', error);
            // Handle error (e.g., show a message to the user)
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            const pageSize = 10;  // Number of items per page
            const url = `${config.REACT_APP_EVAL_API_PATH}/Tests/UserTests?usertype=${selectedUserType}&userid=${userid}&PageNumber=${currentPage}&PageSize=${pageSize}`;
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'API-Key': config.REACT_APP_API_KEY,
                    }
                });

                // Logging data to debug

                console.log("API Header:", response.headers);
                console.log("API Response:", response);
                console.log("Response", tests)
                setTests(response.data || []);  // Ensure the data structure matches
                setTotalPages(Math.ceil(response.data.totalCount / pageSize));  // Adjust based on API response
                const paginationHeader = response.headers['x-pagination'];
                // const paginationData = JSON.parse(paginationHeader);
                console.log("Pagination Data:", paginationHeader);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [config, selectedUserType, userid, currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSendButtonClick = async (tests, docStatus) => {
        const createdon = new Date().toISOString().slice(0, 19).replace("T", " ");
        const user = JSON.parse(localStorage.getItem("user")); // Retrieve user from localStorage
        if (!user) {
            toast.error("User data not found");
            return;
        }
        console.log(tests, "test")

        const headers = {
            "Content-Type": "application/json",
            APIKey: config.REACT_APP_API_KEY,
        };


        const payload = {
            tttds_info_desc: null,
            doc_id: tests,
            createdon: createdon,
            createdby: user.userdetails.agencyid,
            branchid: user.userdetails.branchid,
            docremark: "",
            docdate: createdon,
            actiondate: createdon,
            CreatedBy_empid: user.userdetails.agencyid,
            fwd_empid: user.userdetails.agencyid,
            tat_type_id: 115,
            doc_status: docStatus,
            attached_doc: null,
            attached_doc_name: null,
            doctype: 0,
            draftletter: null,
            tttds_is_final: 0,
            tttds_letter_type: 0,
            docremarkenc: null,
            draftletterenc: null,
        };


        try {
            const response = await axios.post(`${config.REACT_APP_API_URL}/DOCUMENT_STATUS`, payload, {
                headers: headers,
            });
            toast.success("Data sent successfully");
        } catch (error) {
            console.error("Error sending data:", error);
            toast.error("Error sending data");
        }
    };
    const StartTest = () => {
        console.log("Starting")
        navigate('/start-test')
    }




    const renderActionButton = (test) => {
        const handleActionClick = (status) => {
            handleSendButtonClick(test.testID, status);
        };
        const menu = (
            <Menu>
                {test.ttttt_status == 0 || test.ttttt_status == 9 ? (
                    <Menu.Item key="1" onClick={() => handleActionClick(1)}>
                        <Button style={{ fontSize: "15px" }}>Approve</Button>
                    </Menu.Item>
                ) : (
                    <Menu.Item key="2" onClick={() => handleActionClick(9)}>

                        <Button style={{ fontSize: "15px" }}>Suspended</Button>
                    </Menu.Item>
                )}

            </Menu>
        );
        console.log("test-type", test.type);

        if (test.type == 2 && selectedUserType == 5) {
            return <span className="glyphicon  glyphicon-dashboard" title=" View tst result " style={{ fontSize: "16px", margin: "6px", color: "red", cursor: "pointer" }}></span>
        }

        if (test.type !== 2 && selectedUserType == 5) {
            if (test.testparticipantid == null) {
                return <span className="fa fa-play" title=" Start Test " style={{ fontSize: "16px", margin: "6px", color: "red", cursor: "pointer" }} onClick={() => handlePlayClick(test)}></span>
            } else {
                return <span className="glyphicon  glyphicon-dashboard" title=" View tst result " style={{ fontSize: "16px", margin: "6px", color: "chocolate", cursor: "pointer" }}></span>
            }
        }

        if (selectedUserType == 4) {
            return <span className="glyphicon  glyphicon-dashboard" title=" View tst result " style={{ fontSize: "16px", margin: "6px", color: "red", cursor: "pointer" }}></span>
        }

        if (selectedUserType == 1 || selectedUserType == 3) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                    <span className="glyphicon  glyphicon-dashboard" title=" View tst result " style={{ fontSize: "16px", margin: "6px", color: "chocolate", cursor: "pointer" }}></span>
                    <span className="fa  fa-edit" title="Edit" style={{ fontSize: "16px", margin: "6px", color: "chocolate", cursor: "pointer" }}></span>
                    <span className="glyphicon  glyphicon-remove" title="Remove" style={{ fontSize: "16px", margin: "6px", color: "red", cursor: "pointer" }} onClick={() => handleActionClick(-1)}></span>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <span className="glyphicon  glyphicon-cog" title="Change Status" style={{ fontSize: "16px", margin: "6px", color: "gray", cursor: "pointer" }} onClick={e => e.preventDefault()}></span>
                    </Dropdown>
                </div>
            );
        }

        if (test.status == 0 || test.status == 9) {
            return <Button variant="contained" color="warning">Suspended</Button>;
        } else {
            return <Button variant="contained" color="warning"> Action</Button>;
        }
    };



    const paths = [
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Test List', url: '/Eval/TestList' }
    ];

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <p>Error fetching data: {error.message}</p>
            </div>
        );
    }

    return (
        <>
            <Breadcrumb paths={paths} />
            <div style={{ display: 'flex', gap: '10px', padding: '16px', backgroundColor: '#E4E7EA', width: '100%' }}>
                <Input
                    placeholder="Search Test-List"
                    style={{ width: '80%', padding: '8px' }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                <Select
                    placeholder="Training Code"
                    style={{ width: '25%', height: '40px' }}
                    bordered
                >
                    <Option value="">Training Code</Option>
                    {/* Add more Option elements here */}
                </Select>
            </div>
            <div className="table-container">
                <TableContainer component={Paper} style={{ width: "96%", margin: "auto", marginTop: "10px" }}>
                    <Table>
                        <TableHead style={{ backgroundColor: "rgb(66, 139, 202)" }}>
                            <TableRow>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Training Code</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Title</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Category</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Skill Tags</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Date/Day
                                </TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Start Time</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>No of Questions</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Mark per Question</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Duration (min)</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Status</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tests.filter(test =>
                                test.type === 1 &&
                                (test.testName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    test.trainingCode.toLowerCase().includes(searchTerm.toLowerCase()))
                            ).map((test, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.trainingCode}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.testName}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.trainingcategoryname}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.skillTag}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {test.type == 2 ? test.ttttt_session_day : test.ttttt_session_time}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.start_time || 'N/A'}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.noOfQuestion}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.mark_per_question}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {test.assesmentTime * test.noOfQuestion}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.tdds_status == 1 ? 'Approved' : 'Suspended'}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }}>
                                        <TableCell variant="contained" color="primary" style={{ display: 'flex', flexDirection: 'row', borderBottom: "none" }}>
                                            {renderActionButton(test)}
                                        </TableCell>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </div>
            <div className="card-container">
                {tests.filter(test => test.type == 1).map((test, index) => (
                    <div className="card" key={index}>
                        <div className="card-header">

                            {test.trainingCode}
                        </div>
                        <div className="card-body">
                            <p>Title:{test.testName}</p>
                            <p>Category: {test.trainingcategoryname}</p>
                            <p>Skill Tags: {test.skillTag}</p>
                            <p>Date: {new Date(test.createdOn).toLocaleDateString()}</p>
                            <p>Start Time: {test.start_time || 'N/A'}</p>
                            <p>No of Questions:{test.noOfQuestion}</p>
                            <p>Mark per Question:{test.mark_per_question}</p>
                            <p>Duration (min): {test.ttttt_session_duration}</p>
                            <p>Status: {test.isActive ? 'Active' : 'Inactive'}</p>
                            <TableCell variant="contained" color="primary" style={{ display: 'flex', flexDirection: 'row', borderBottom: "none" }}>

                                {renderActionButton(test)}



                            </TableCell>
                        </div>

                    </div>
                ))}
            </div>
            <div className="pagination-container" style={{ display: 'flex', justifyContent: 'end', padding: '16px' }}>
                <Button
                    variant="contained"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={{ marginRight: "5px" }}>
                    Previous
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>

            </div>

            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                PaperProps={{
                    style: { width: '340px' }
                }}
            >
                <DialogTitle>Alert</DialogTitle>
                <DialogContent>
                    <p>{modalMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default TestListTable;
