import React, { useEffect, useState } from 'react';
import axios from 'axios';
import loadConfig from '../../config';
import { useUser } from '../../UserContext';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const LoginPost = () => {
   
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [config, setConfig] = useState(null);
    const [data, setData] = useState(null); // State to hold API response data
    const { setUser, setClient, setSelectedUserType, } = useUser(); // Access setUser from UserContext
    const fetchConfig = async () => {
        try {
            console.log("Loading config data...");
            const configData = await loadConfig; // Assuming loadConfig fetches your config object
            console.log("Config data loaded:", configData);
            setConfig(configData);
            
            console.log("ccc", configData);
            //fetchAdditionalData();
            return configData;


        } catch (error) {
            console.error("Error loading config:", error);
        }
    };


    const createdby = "7AB3FE43-ECCC-7C2A-24FA-FFF9EEEE5DD6"
    useEffect(() => {
        const initializeConfig = async () => {
            const configData = await fetchConfig();
            if (configData) {
                fetchAdditionalData(configData)
            }
        };
        initializeConfig();
    }, []);

    const fetchAdditionalData = async (config, page = 1, pageSize = 4) => {
        console.log("AAA in fun", config)
        // console.log("AAA", config.REACT_APP_API_KEY)
        try {
            const headers = {
                Accept: "application/json, text/plain",
                APIKey: "UHJhdGhhbXNvZnRTZWNyZXRLZXkxMjM=", // Use config.REACT_APP_API_KEY instead of process.env.REACT_APP_REACT_APP_API_KEY
                "Content-Type": "application/json",
            };

            const response = await axios.get(`http://p9:5858/api/userinfo?username=kuldeep@prathamsoft.com`, {
                headers,

            });

            console.log(config.REACT_APP_API_URL)

            // Handle the response data as needed

            setUser(response.data.result);
            localStorage.setItem('user', JSON.stringify(response.data.result));
            console.log("All data", response);
            navigate("/dashboard");
            const shouldOpenModal = response.data.result.userdetails.usertype.length > 1;

        if (!shouldOpenModal) {
          // Navigate to dashboard if modal should not open
          setSelectedUserType(response.data.result.userdetails.usertype[0].usertypeid)
          navigate("/dashboard");
          toast.success("Login successful!");
        } else {
          // Open modal if necessary
          setIsOpen(true);
          toast.success("Login successful!");
        }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    return (
        <div>
            <h2>LoginPost</h2>
            {data ? (
                <div>
                    <h3>Data from API:</h3>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default LoginPost;


