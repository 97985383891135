import React, { useContext, useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box } from '@mui/material';
import Breadcrumb from '../commonComponent/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import RocketIcon from '@mui/icons-material/Rocket';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Input, Select, DatePicker, Space } from 'antd';
import moment from 'moment';
import axios from 'axios';
import 'antd/dist/reset.css';
import { TranslationContext } from '../../TranslationContext';
import { NavLink } from 'react-router-dom';
import { useConfig } from '../../ConfigContext';
import { useUser } from '../../UserContext';
import { formatDuration } from '../utils/Validation';
import "./meeting.css"
import EmailModal from './EmailModal';
import ConfirmDeleteModal from './ConfiromDelet'; // Import the new modal
import LinkModal from './LinkModal';
const { Option } = Select;
const { RangePicker } = DatePicker;

const MeetingList = () => {
    const config = useConfig();
    const { selectedUserType, selectedYear } = useUser();
    const { translations } = useContext(TranslationContext);
    const [meetingData, setMeetingData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const user = JSON.parse(localStorage.getItem('user'));
    const [sortConfig, setSortConfig] = useState({ key: 'ttlm_date', direction: 'asc' });
    const [passwordM, setPasswordM] = useState(false);
    const userid = user.userdetails.agencyid;
    const [modalOpen, setModalOpen] = useState(false);
    const [linkToCopy, setLinkToCopy] = useState('');
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); // New state for delete modal
    const [meetingToDelete, setMeetingToDelete] = useState(null); // Store meeting to delete
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Adjust as needed
    const [totalItems, setTotalItems] = useState(0); // Total items
    const [selectedOption, setSelectedOption] = useState('');
    const [dateRangeVisible, setDateRangeVisible] = useState(false);
    const userty = user.userdetails.usertype[0].usertypeid;

    // console.log("usertye", userty);
    const handleSelectChange = (value) => {
        setSelectedOption(value);
        if (value === 'Period') {
            setDateRangeVisible(true);
        } else {
            setDateRangeVisible(false);
        }
    };
    useEffect(() => {
        const branchid = user.userdetails.branchid;
        const fetchData = async () => {
            const url = `${config.REACT_APP_API_URL}/Meetings?finyear=${selectedYear}&branchid=${branchid}&usertype=${selectedUserType}&userid=${userid}&PageNumber=${currentPage}&PageSize=${pageSize}`;
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        APIKey: config.REACT_APP_API_KEY,
                    }         
                });
                setMeetingData(response.data.items);
                setTotalItems(response.data.totalRecords); // Set total items from response
                setTotalPages(response.data.totalPages); // Calculate total pages
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [selectedYear, config, selectedUserType, userid, currentPage, itemsPerPage]);


    const sortData = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const sortedData = [...meetingData].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        setMeetingData(sortedData);
        setSortConfig({ key, direction });
    };

    const handleChangePassword = () => {
        setPasswordM(!passwordM);
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
        }
    };
    const handleRedirection = (ttlm, userid, trainingid) => {
        const {
            associate_course_director_id,
            course_director_id,
            ttlm_z_start_link,
            ttlm_z_join_link,
            ttlm_host_id
        } = ttlm;

        // Check if trainingid is present
        if (trainingid != "") {
            // Check if either associate_course_director_id or course_director_id matches the userid
            if (associate_course_director_id.toString().toUpperCase() == userid.toString().toUpperCase() || course_director_id.toString().toUpperCase() == userid.toString().toUpperCase()) {
                window.open(ttlm_z_join_link, '_blank');
                return; // Exit the function after redirection
            }
        }

        // Original logic
        if (associate_course_director_id.toString().toUpperCase() == '' && course_director_id.toString().toUpperCase() == '') {
            window.open(ttlm_z_join_link, '_blank');
        } else if (userid.toString().toUpperCase() == ttlm_host_id.toString().toUpperCase()) {
            // window.location.href = ttlm_z_start_link;
            window.open(ttlm_z_start_link, '_blank');
        }
    };

    const shouldShowAllIcons = (meeting) => {
        const {
            associate_course_director_id,
            course_director_id,
            ttlm_host_id,
            trainingid
        } = meeting;

        // Check if trainingid exists and if any of the conditions match
        if (trainingid != "") {
            return (
                associate_course_director_id.toString().toUpperCase() == ttlm_host_id.toString().toUpperCase() ||
                course_director_id.toString().toUpperCase() == ttlm_host_id.toString().toUpperCase()

            );
        }

        // Check if trainingid is blank and userid matches ttlm_host_id
        return userid.toString().toUpperCase() == ttlm_host_id.toString().toUpperCase();
    };

    //==============Copy Link =================//

    const handleCopy = async (link) => {
        const apiKey = config?.REACT_URL_SHORTNER_KEY;
        const getApiUrl = config?.REACT_URL_SHORTNER_PATH;
        const postApiUrl = config?.REACT_URL_SHORTNER_PATH;

        const postData = { longFormat: link };

        try {
            const getResponse = await axios.get(getApiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    'API-Key': apiKey,
                }
            });

            const existingUrls = getResponse.data;
            const matchedUrl = existingUrls.find(url => url.longFormat === link);
            const sitename = window.location.protocol + "//" + window.location.host
            if (matchedUrl) {
                setLinkToCopy(`${sitename}/redarict/${matchedUrl.shortFormat}`);
                setModalOpen(true);
            } else {
                const postResponse = await axios.post(postApiUrl, postData, {
                    headers: {
                        "Content-Type": "application/json",
                        'API-Key': apiKey
                    }
                });

                const shortUrl = postResponse.data.shortFormat;
                setLinkToCopy(`${sitename}/redarict/${shortUrl}`);
                setModalOpen(true);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleCopyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(linkToCopy);
            console.log("Copied to clipboard:", linkToCopy);
            setModalOpen(false);
        } catch (error) {
            console.error("Error copying to clipboard:", error);
        }
    };
    //==================Send Message in Whatshapp==================//
    const handleIconClick = (meeting) => {
        // Construct the message
        const message = `Title: ${meeting.training_title}\nTime: ${meeting.ttlm_time}\nHost: ${meeting.host}\nDate: ${meeting.date}`;

        // Encode the message for URL
        const encodedMessage = encodeURIComponent(message);

        // Construct the WhatsApp share URL
        const whatsappURL = `https://api.whatsapp.com/send?text=${encodedMessage}`;

        // Open the WhatsApp URL
        window.open(whatsappURL, '_blank');
    };

    //=====================Delete to Metting==================//

    const handleDelete = (meeting) => {
        setMeetingToDelete(meeting);
        setDeleteModalOpen(true);
    };

    const confirmDelete = async () => {
        if (meetingToDelete) {
            const { ttlm_id, ttlm_zid, tttlm_ttlms_LitteraMeetingID } = meetingToDelete;

            try {
                // First API Call: GET request
                const response1 = await axios.get(`${config.REACT_CONFERENCE_API_PATH}/${ttlm_zid}/${tttlm_ttlms_LitteraMeetingID}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        APIKey: config.REACT_APP_API_KEY,
                    }
                });

                // Check if the first API call was successful
                if (response1.status == 200) {
                    console.log("First API call successful. Response:", response1.data);

                    // Second API Call: DELETE request
                    const response2 = await axios.delete(`${config.REACT_APP_API_URL}/DeleteMeeting/${ttlm_id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            APIKey: config.REACT_APP_API_KEY,
                        }
                    });

                    // Check if the second API call was successful
                    if (response2.status == 200) {
                        console.log("Second API call successful. Response:", response2.data);

                        // Update the state to remove the deleted meeting
                        setMeetingData(meetingData.filter(meeting => meeting.ttlm_id !== ttlm_id));
                        setDeleteModalOpen(false);
                        setMeetingToDelete(null);
                    } else {
                        console.error('Error deleting meeting:', response2.statusText);
                        alert('Failed to delete the meeting. Please try again later.');
                    }
                } else {
                    console.error('Error confirming conference:', response1.statusText);
                    alert('Failed to confirm the conference. Please try again later.');
                }
            } catch (error) {
                console.error('Error during delete operations:', error);
                alert('An error occurred. Please try again later.');
            }
        }
    };



    const handleCloseModal = () => {
        setDeleteModalOpen(false);
        setMeetingToDelete(null);
    };
    const paths = [
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Meeting List', url: '/meeting-list' }
    ];
    return (
        <>
            <ConfirmDeleteModal
                open={deleteModalOpen}
                onClose={handleCloseModal}
                onConfirm={confirmDelete}
            />
            <Breadcrumb paths={paths} />
            <div style={{ display: 'flex', gap: '10px', padding: '16px', backgroundColor: '#E4E7EA', width: '100%', position: 'relative' }}>
                {!dateRangeVisible ? (
                    <Input
                        placeholder="Search Meeting List"
                        style={{ padding: '8px', width: '80%' }}
                    />
                ) : (
                    <div style={{ width: '80%' }}>
                        <RangePicker
                            format="YYYY-MM-DD"
                            defaultValue={[
                                moment().startOf('month'),
                                moment().endOf('month')
                            ]}
                            style={{ width: '100%', padding: "9px" }}
                        />
                    </div>
                )}
                <Select
                    placeholder="Training Code"
                    style={{ width: '25%', height: '40px' }}
                    bordered
                    onChange={handleSelectChange}
                >
                    <Option value="Topic">Topic</Option>
                    <Option value="Agenda">Agenda</Option>
                    <Option value="Training Code">Training Code</Option>
                    <Option value="Period">Period</Option>
                </Select>
            </div>
            {userty !== 5 ? (

                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                    <NavLink to="">
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ fontSize: "15px", margin: "10px" }}
                        >
                            {translations["Create_Meeting_button"] || "CREATE MEETING"}
                        </Button>
                    </NavLink>
                </Box>
            ) : null}

            <div className="table-container">
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead style={{ backgroundColor: "rgb(66, 139, 202)" }}>
                            <TableRow>
                                <TableCell style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }} className='feedbck-titel' onClick={() => sortData('training_title')}>
                                    Training {sortConfig.key === 'training_title' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▼'}
                                </TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }} className='feedbck-titel' onClick={() => sortData('ttlm_agenda')}>
                                    Agenda {sortConfig.key === 'ttlm_agenda' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▼'}
                                </TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }} className='feedbck-titel' onClick={() => sortData('ttlm_date')}>
                                    Date {sortConfig.key === 'ttlm_date' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▼'}
                                </TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }} className='feedbck-titel' onClick={() => sortData('ttlm_time')}>
                                    Start Time {sortConfig.key === 'ttlm_time' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▼'}
                                </TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }} className='feedbck-titel' onClick={() => sortData('ttlm_duration')}>
                                    Duration {sortConfig.key === 'ttlm_duration' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▼'}
                                </TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }} className='feedbck-titel' onClick={() => sortData('ttlm_title')}>
                                    Topic {sortConfig.key === 'ttlm_title' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▼'}
                                </TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }}></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {meetingData.map((meeting, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {meeting.training_title.trim() === '' && meeting.training_code.trim() === ''
                                            ? 'Personal Training'
                                            : `${meeting.training_title}[${meeting.training_code}]`}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {meeting.ttlm_agenda}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {moment(meeting.ttlm_date).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {meeting.ttlm_time}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {formatDuration(meeting.ttlm_duration)}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {meeting.ttlm_title}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd', display: 'flex', gap: '1px', justifyContent: "space-around", height: "74px" }}>
                                        {shouldShowAllIcons(meeting) ? (
                                            <>
                                                <IconButton aria-label="rocket" style={{ padding: "0px" }} onClick={() => handleRedirection(meeting, userid, meeting.trainingid)}>
                                                    <RocketIcon style={{ fontSize: "20px", color: "#078d75", transform: "rotate(45deg)" }} />
                                                </IconButton>
                                                <IconButton aria-label="mail" style={{ padding: "0px" }} onClick={handleChangePassword}>
                                                    <MailIcon style={{ fontSize: "20px", color: "brown" }} />
                                                </IconButton>
                                                <IconButton aria-label="whatsapp" style={{ padding: "0px" }} onClick={() => handleIconClick(meeting)}>
                                                    <WhatsAppIcon style={{ fontSize: "20px", color: "#25D366" }} />
                                                </IconButton>
                                                <IconButton aria-label="copy" style={{ padding: "0px" }} onClick={() => handleCopy(meeting.ttlm_z_join_link)}>
                                                    <CopyAllIcon style={{ fontSize: "20px", color: "blue" }} />
                                                </IconButton>
                                                <IconButton aria-label="edit" style={{ padding: "0px" }}>
                                                    <EditIcon style={{ fontSize: "20px", color: "blue" }} />
                                                </IconButton>
                                                <IconButton aria-label="delete" style={{ padding: "0px" }} onClick={() => handleDelete(meeting)}>
                                                    <DeleteIcon style={{ fontSize: "20px", color: "red" }} />
                                                </IconButton>
                                            </>
                                        ) : (
                                            <IconButton aria-label="rocket" style={{ padding: "0px" }} onClick={() => handleRedirection(meeting, userid, meeting.trainingid)}>
                                                <RocketIcon style={{ fontSize: "20px", color: "#078d75", transform: "rotate(45deg)" }} />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div className="card-container">
                {meetingData.map((meeting, index) => (
                    <div className="card" key={index}>
                        <div className="card-header">
                            {meeting.training_title.trim() === '' && meeting.training_code.trim() === ''
                                ? 'Personal Training'
                                : `${meeting.training_title}[${meeting.training_code}]`}
                        </div>
                        <div className="card-body">
                            <p>Agenda: {meeting.ttlm_agenda}</p>
                            <p>Date: {moment(meeting.ttlm_date).format('DD/MM/YYYY')}</p>
                            <p>Start Time: {meeting.ttlm_time}</p>
                            <p>Duration: {formatDuration(meeting.ttlm_duration)}</p>
                            <p>Topic: {meeting.ttlm_title}</p>
                        </div>
                        <div className="card-footer">
                            {shouldShowAllIcons(meeting) ? (
                                <>
                                    <IconButton aria-label="rocket" onClick={() => handleRedirection(meeting, userid, meeting.trainingid)}>
                                        <RocketIcon style={{ fontSize: "20px", color: "#078d75", transform: "rotate(45deg)" }} />
                                    </IconButton>
                                    <IconButton aria-label="mail">
                                        <MailIcon style={{ fontSize: "20px", color: "brown" }} />
                                    </IconButton>
                                    <IconButton aria-label="whatsapp">
                                        <WhatsAppIcon style={{ fontSize: "20px", color: "#25D366" }} />
                                    </IconButton>
                                    <IconButton aria-label="copy" style={{ padding: "0px" }} onClick={() => handleCopy(meeting.ttlm_z_join_link)}>
                                        <CopyAllIcon style={{ fontSize: "20px", color: "blue" }} />
                                    </IconButton>
                                    <IconButton aria-label="edit">
                                        <EditIcon style={{ fontSize: "20px", color: "blue" }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => handleDelete(meeting)}>
                                        <DeleteIcon style={{ fontSize: "20px", color: "red" }} />
                                    </IconButton>
                                </>
                            ) : (
                                <IconButton aria-label="rocket" onClick={() => handleRedirection(meeting, userid, meeting.trainingid)}>
                                    <RocketIcon style={{ fontSize: "20px", color: "#078d75", transform: "rotate(45deg)" }} />
                                </IconButton>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <div style={{ textAlign: 'end', margin: '20px 10px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                >
                    Previous
                </Button>
                <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>



            <EmailModal passwordM={passwordM} handleChangePassword={handleChangePassword} />
            <LinkModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onCopy={handleCopyToClipboard}
            />

        </>
    );
};

export default MeetingList;

